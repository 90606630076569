import { SITE_NAME } from 'lib/constants';

const title = 'Cut Into The Jamstack: Build a SaaS app with the Jamstack';
const description =
  "Cut Into The Jamstack is a book in which you'll learn to build full-stack Jamstack applications with React and Next.js by building one yourself.";

export default {
  title,
  description,
  openGraph: {
    url: process.env.SITE_BASE_URL,
    title,
    description,
    images: [
      {
        url: `${process.env.SITE_BASE_URL}/cutintothejamstack-opengraph.jpeg`,
        width: 1200,
        height: 630,
        alt: 'Cut Into The Jamstack book cover',
      },
    ],
    site_name: SITE_NAME,
    type: 'website',
  },
  twitter: {
    handle: '@mcavaliere',
    cardType: 'summary',
  },
};
