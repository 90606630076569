import { ReactNode } from 'react';
import { Footer } from 'components/Footer';
import { Navbar } from 'components/Navbar';

export type SiteOuterLayoutProps = {
  children: ReactNode;
};

export const SiteOuterLayout = ({ children }: SiteOuterLayoutProps) => (
  <>
    <Navbar />
    {children}
    <Footer />
  </>
);

export const getLayout = (page) => {
  return <SiteOuterLayout>{page}</SiteOuterLayout>;
};

export default SiteOuterLayout;
