// 1. Import `extendTheme`
import { extendTheme } from '@chakra-ui/react';
// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    blue: {
      brand: '#01608f7f',
    },
    primary: '#d2725c',
    chapter: {},
    brand: {
      red: '#D36A5D',
      fadedRed: '#F5E1DF',
      yellow: '#DDAE3B',
      fadedYellow: '#FDF4D5',
    },
    accent: '#2a1e1c',
    beige: '#c1b19f',
    lightBeige: '#f2f1f0',
    lightGreen: '#F4F8F7',
    teal: '#588c94',
  },
  fonts: {
    heading: 'Oswald',
    body: 'Raleway',
  },
});

export default theme;
