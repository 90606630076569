import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaTwitter, FaMedium, FaLinkedin } from 'react-icons/fa';
import { ReactNode } from 'react';
import { EBLogo } from 'svgs/EBLogo';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded="full"
      w={8}
      h={8}
      cursor="pointer"
      as="a"
      href={href}
      target="_blank"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export function Footer() {
  return (
    <Box color={useColorModeValue('gray.700', 'gray.200')} py={2}>
      <Container
        as={Stack}
        maxW="6xl"
        py={4}
        spacing={2}
        justify="center"
        align="center"
      >
        <Text
          color="gray.400"
          fontSize={14}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Supported by
        </Text>

        <a href="https://echobind.com" target="_blank" rel="noreferrer">
          <EBLogo width={150} />
        </a>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.700')}
      >
        <Container
          as={Stack}
          maxW="6xl"
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Text>
            © 2022{' '}
            <Link
              href="https://mikecavaliere.com/"
              textDecoration="underline"
              target="_blank"
            >
              Mike Cavaliere
            </Link>
            . All rights reserved
          </Text>
          <Stack direction="row" spacing={6}>
            <SocialButton label="Twitter" href="https://twitter.com/mcavaliere">
              <FaTwitter />
            </SocialButton>

            <SocialButton
              label="Medium"
              href="https://medium.com/@mikecavaliere"
            >
              <FaMedium />
            </SocialButton>

            <SocialButton
              label="LinkedIn"
              href="https://www.linkedin.com/in/mikecavaliere"
            >
              <FaLinkedin />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
