import {
  Box,
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  IconButton,
  Image,
  Stack,
  StackProps,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const MotionBox = motion<BoxProps>(Box);
const MotionStack = motion<StackProps>(Stack);

import {
  ConditionalScrollLink,
  ConditionalScrollButton,
} from 'components/ConditionalScroll';

import { useRouter } from 'next/router';

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'List of Technologies',
    href: '/#section-technologies',
  },
  {
    label: 'About the Book',
    href: '/#section-summary',
  },
  {
    label: 'Book Features',
    href: '/#section-features',
  },
  {
    label: 'Table of Contents',
    href: '/toc',
  },
  {
    label: 'Buy Now',
    href: '/#section-pricing',
  },
  {
    label: 'Mailing List',
    href: '/#section-newsletter',
  },
  {
    label: 'Articles',
    href: '/articles',
  },
];

export function Navbar() {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const router = useRouter();
  const isHome = router.pathname === '/';

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}
      >
        {/* Hamburger icon */}
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

        {/* Logo link to homepage; only show on subpages */}
        {!isHome && (
          <Flex grow={1} align="center" justify="center" width="100%">
            <ConditionalScrollLink
              href="/"
              display="block"
              _hover={{ textDecoration: 'none', width: 225 }}
              overflow="hidden"
              height={50}
              width={50}
              transition="width 0.3s ease-in-out"
              wordBreak="keep-all"
              whiteSpace="nowrap"
            >
              <Image
                src="/toast.png"
                boxSize={50}
                objectFit="contain"
                mr={1}
                loading="eager"
                alt="Cut Into The Jamstack book logo"
                display="inline-block"
              />
              <Text
                fontFamily="'Knewave', cursive;"
                display="inline-block"
                width={175}
              >
                Cut Into The
                <Text
                  as="span"
                  display="inline-block"
                  fontWeight="bold"
                  color="#c37465"
                  ml={1}
                >
                  Jamstack
                </Text>
              </Text>
            </ConditionalScrollLink>
          </Flex>
        )}

        {/* Buy Now button */}
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
        >
          <ConditionalScrollButton
            href="/#section-pricing"
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            bg={'accent'}
            textDecoration="none"
            _hover={{
              bg: 'primary',
              textDecoration: 'none',
            }}
          >
            Buy Now
          </ConditionalScrollButton>
        </Stack>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <DrawerOverlay>
          <DrawerContent zIndex={0}>
            <DrawerCloseButton zIndex={1} />

            <DrawerBody>
              <MobileNav onNavItemClick={onClose} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
}
const container = {
  hidden: {},
  show: {
    transition: {
      easing: 'easeInOut',
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { left: -100, opacity: 0 },
  show: { left: 0, opacity: 1 },
  // initial: { left: 0, scale: 1 },
  // hover: {
  //   scale: 1.2,
  // },
};
const MobileNav = ({ onNavItemClick }: { onNavItemClick: () => void }) => {
  return (
    <MotionStack
      p={4}
      spacing="20px"
      direction="column"
      initial="hidden"
      animate="show"
      variants={container}
      position="relative"
      overflow="hidden"
    >
      {NAV_ITEMS.map(({ label, href }) => {
        return (
          <MotionBox
            variants={item}
            key={label}
            position="relative"
            whileHover={{ scale: 1.2 }}
            transformOrigin="top left"
          >
            <ConditionalScrollLink
              background="transparent"
              href={href}
              textDecoration="none"
              onClick={onNavItemClick}
              _hover={{
                textDecoration: 'none',
              }}
            >
              {label}
            </ConditionalScrollLink>
          </MotionBox>
        );
      })}
    </MotionStack>
  );
};

interface NavItem {
  label: string;
  href?: string;
  scrollTo?: string;
}
