import { FC, ReactNode } from 'react';
import { Button, ButtonProps, Link, LinkProps } from '@chakra-ui/react';
import NextLink from 'next/link';

export type ConditionalScrollBaseProps = {
  children: ReactNode;
  href: string;
  as: any;
  target?: string;
  onClick?: ButtonProps['onClick'];
};

export type ConditionalScrollLinkProps = LinkProps &
  Omit<ConditionalScrollBaseProps, 'as'>;

export type ConditionalScrollButtonProps = ButtonProps &
  Omit<ConditionalScrollBaseProps, 'as'>;

export const ConditionalScrollBase: FC<ConditionalScrollBaseProps> = ({
  children,
  as: Component,
  href,
  onClick,
  ...rest
}: ConditionalScrollBaseProps) => {
  const urlParts = href.split(/#/);
  const anchor = urlParts.length > 0 ? urlParts[1] : undefined;

  const props = {
    ...rest,
  };

  props['onClick'] = (event) => {
    // Call passed-in onClick handler first, if it exists.
    if (onClick) {
      onClick(event);
    }

    // Make sure the elements we need exist and are accessible.
    if (!document || !anchor) {
      return;
    }

    // Find the element we need to scroll to; return if it's not found.
    const target = document.getElementById(anchor);
    if (!target) {
      return;
    }

    // Prevent page jumping.
    event.preventDefault();

    // Scroll to the element.
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  // Otherwise just link to the page, using next/link for speed.
  return (
    <NextLink href={href} passHref>
      <Component {...props} as="span">
        {children}
      </Component>
    </NextLink>
  );
};

export const ConditionalScrollButton: FC<ConditionalScrollButtonProps> = ({
  children,
  ...rest
}: ConditionalScrollButtonProps) => {
  return (
    <ConditionalScrollBase as={Button} {...rest}>
      {children}
    </ConditionalScrollBase>
  );
};

export const ConditionalScrollLink: FC<ConditionalScrollLinkProps> = ({
  children,
  ...rest
}: ConditionalScrollLinkProps) => {
  return (
    <ConditionalScrollBase as={Link} {...rest}>
      {children}
    </ConditionalScrollBase>
  );
};
