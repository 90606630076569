import '@fontsource/raleway';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';

import { ReactElement, ReactNode, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import type { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { theme } from 'lib/theme';
import * as analytics from 'lib/analytics';
import SEOConfig from '../next-seo.config';

import { getLayout as getSiteOuterLayout } from 'layouts/SiteOuterLayout';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  // Record Google Analytics pageviews when route changes.
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      analytics.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // Grab the layout if set; otherwise default to the default layout.
  const getLayout = Component.getLayout || getSiteOuterLayout;

  return (
    <>
      <DefaultSeo {...SEOConfig} />
      <ChakraProvider theme={theme}>
        {getLayout(<Component {...pageProps} />, pageProps)}
      </ChakraProvider>
    </>
  );
}

export default MyApp;
